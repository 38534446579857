
// Helper function to convert date-time string to Date object
export const parseDateTime = dateTimeString => new Date(dateTimeString);

// Convert date-time string to a formatted string without milliseconds and timezone
export function convertDateFormat(dateTimeString) {
    const dateObj = new Date(dateTimeString);
    const adjustedTime = new Date(dateObj.getTime() - (dateObj.getTimezoneOffset() * 60000)).toISOString();
    return adjustedTime.slice(0, 19); // 2024-08-01T09:00:00
}


// Function to round the time to the nearest 10 minutes
export function roundToNearestTenMinutes(date) {
    const minutes = date.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 10) * 10;
    date.setMinutes(roundedMinutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

// Function to divide time slots into intervals, ensuring they do not extend beyond the specified time
export const divideTimeSlots = (arr, durationInMinutes) => {

    // working fine with omitting time but 5:03 aese agar areh hai toh show ni karana joh kay abhi ho rhai hai
    // const duration = durationInMinutes * 60 * 1000; // Convert minutes to milliseconds
    // const overlap = 10 * 60 * 1000; // Convert overlap minutes to milliseconds

    // // Convert current time to a Date object
    // const now = parseDateTime(new Date());

    // return arr.map(day => {
    //     const newTimeSlots = [];
    //     day.timeSlots.forEach(slot => {
    //         let start = parseDateTime(slot.from);
    //         const end = parseDateTime(slot.to?.split("+")[0]);

    //         // Skip this time slot if its end time is before the current time
    //         if (end <= now) return;

    //         // Adjust the start time if it falls before the current time
    //         if (start < now) {
    //             start = now;
    //         }

    //         while (start < end) {
    //             // Calculate the end of the interval based on the duration
    //             const intervalEnd = new Date(start.getTime() + duration);

    //             // Ensure the interval does not exceed the slot's end time
    //             const actualEnd = intervalEnd > end ? end : intervalEnd;

    //             // Only add intervals that fit within the current time slot
    //             if (actualEnd > start) {
    //                 // Add the interval only if its duration is at least the required duration
    //                 if (actualEnd - start >= duration) {
    //                     newTimeSlots.push({
    //                         from: convertDateFormat(start),
    //                         to: convertDateFormat(actualEnd) + '+00:00',
    //                     });
    //                 }
    //             }

    //             // Move start forward by the overlap amount for the next interval
    //             start = new Date(start.getTime() + overlap);

    //             // Exit the loop if the start time has reached or exceeded the slot's end time
    //             if (start >= end) break;
    //         }
    //     });

    //     // Return the day with the new time slots
    //     return { ...day, timeSlots: newTimeSlots };
    // });


    // working fine with time ommiting functinality and setting time to around 10 minutes
    const duration = durationInMinutes * 60 * 1000; // Convert minutes to milliseconds
    const overlap = 10 * 60 * 1000; // Convert overlap minutes to milliseconds

    // Convert current time to a Date object and round to the nearest 10 minutes
    let now = parseDateTime(new Date());
    now = roundToNearestTenMinutes(now);

    return arr.map(day => {
        const newTimeSlots = [];
        day.timeSlots.forEach(slot => {
            let start = parseDateTime(slot.from);
            const end = parseDateTime(slot.to?.split("+")[0]);

            // Skip this time slot if its end time is before the current time
            if (end <= now) return;

            // Adjust the start time if it falls before the current time
            if (start < now) {
                start = now;
            } else {
                // Round the next time slot start time if it starts after the current time
                start = roundToNearestTenMinutes(start);
            }

            while (start < end) {
                // Calculate the end of the interval based on the duration
                const intervalEnd = new Date(start.getTime() + duration);

                // Ensure the interval does not exceed the slot's end time
                const actualEnd = intervalEnd > end ? end : intervalEnd;

                // Only add intervals that fit within the current time slot
                if (actualEnd > start) {
                    // Add the interval only if its duration is at least the required duration
                    if (actualEnd - start >= duration) {
                        newTimeSlots.push({
                            from: convertDateFormat(start),
                            to: convertDateFormat(actualEnd) + '+00:00',
                        });
                    }
                }

                // Move start forward by the overlap amount for the next interval
                start = new Date(start.getTime() + overlap);

                // Exit the loop if the start time has reached or exceeded the slot's end time
                if (start >= end) break;
            }
        });

        // Return the day with the new time slots
        return { ...day, timeSlots: newTimeSlots };
    });
};



export const todayAvailableAppointment = (dateTime, appointments) => {
    const dateString = dateTime.toISOString().split('T')[0];
    for (let appointment of appointments) {
        if (appointment.date.split('T')[0] === dateString) {
            return true
        }
    }
    return null;
};


export function getFirstDayOfMonth(currentMonth) {
    const date = new Date(currentMonth);
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return new Date(firstDay.getTime() - firstDay.getTimezoneOffset() * 60000).toISOString()?.split("T")[0];
};
