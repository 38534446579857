import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ErrorMsg from '../ErrorMsg'
import teamMemberInstance from '../../Instance/teamMemberInstance'
import Spinner from '../Spinner'

const ServiceSelection = ({ setTeamMemberId, teamMemberId, setInnerModal, serviceObj, setServicesToShow, servicesToShow }) => {
    const [obj, setObj] = useState({})
    const [productVariants, setProductVariants] = useState(serviceObj?.productVariants ? serviceObj?.productVariants : [])
    const [teamMemberListToShow, setTeamMemberListToShow] = useState([])
    const [loader, setLoader] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)

    // block dropdown if services is greater than 0
    const blockDropdown = () => {
        return servicesToShow?.length > 0 ? true : false
    }

    const handleCheckBox = async (value) => {
        try {
            const serviceIds = serviceObj?.productId;
            const body = {
                serviceIds: [serviceIds],
                variantIds: [value?.variantId]
            };
            setLoader(true)
            setBtnLoader(true)
            const res = await teamMemberInstance.post(`TeamMemberServices`, body);
            const staffName = res?.data?.data?.find((item) => item?.teamMemberId === value?.teamMemberId)
            setObj({
                ...obj,
                teamMemberId: servicesToShow?.length > 0 ? obj?.teamMemberId : null,
                staff: staffName?.name, variantId: value?.variantId, durationMinutes: value?.duration ? value?.duration : "10", serviceVariationName: value?.name, itemName: serviceObj?.productName, amount: value?.price, serviceVariationId: value?.variantId, serviceVariationVersion: value?.variantVersion, teamId: value?.teamMemberId
            })
            setBtnLoader(false)
            setLoader(false)
            // Fetch team members based on the selected variant
            await gettingTeamMember(value?.variantId);
        } catch (err) {
            setLoader(false)
            setBtnLoader(false)
        }
    }

    const handleSave = () => {
        try {
            if (!obj?.teamMemberId) {
                toast.warning("Staff is requried")
            } else if (!obj?.variantId) {
                toast.warning("Variant is requried")
            } else {
                setServicesToShow([...servicesToShow, { ...obj, teamMemberId: obj?.teamMemberId, durationMinutes: obj?.durationMinutes, itemName: serviceObj?.productName, itemId: serviceObj?.productId }])
                setInnerModal("")
                toast.success("Succesfully Added")
                setTeamMemberId(obj?.teamMemberId)
            }
        } catch (err) {
            toast.error("Some error occured")
        }
    }

    // getting teamMember according to product Id
    const gettingTeamMember = async (variantId) => {
        try {
            const serviceIds = serviceObj?.productId;
            const body = {
                serviceIds: [serviceIds],
                variantIds: [variantId]
            };
            setLoader(true)
            const res = await teamMemberInstance.post(`TeamMemberServices`, body);
            setTeamMemberListToShow(res?.data?.data);
            setLoader(false)
        } catch (error) {
            setLoader(false)
            toast.error("Some error occurred getting team member");
        }
    }

    useEffect(() => {
        const regularObject = productVariants.find(item => item?.name.toLowerCase().includes("regular".toLowerCase()));
        const staffName = teamMemberListToShow.find(item => item?.teamMemberId === teamMemberId);
        // If a regular object is found, set default values
        if (regularObject) {
            setObj(prevObj => ({
                ...prevObj,
                staff: servicesToShow?.length > 0 ? servicesToShow[0]?.staff : staffName?.name,
                teamMemberId: teamMemberId,
                variantId: regularObject?.variantId,
                teamId: regularObject?.teamMemberId,
                serviceVariationName: regularObject?.name,
                serviceVariationId: regularObject?.variantId,
                serviceVariationVersion: regularObject?.variantVersion,
                durationMinutes: regularObject?.duration,
                amount: regularObject?.price,
            }));
            // Fetch team members only if regularObject is found
            gettingTeamMember(regularObject?.variantId);
        }
        else {
            // Reset the team member ID and other related states if no regularObject is found
            setObj(prevObj => ({
                ...prevObj,
                teamMemberId: teamMemberId,
            }));
        }
    }, [productVariants, teamMemberId]);


    return (
        <div className='fixed top-0 left-0 w-full bg-[#4a4a4aa0] z-[99999] h-full drop-shadow-2xl  flex justify-center items-center'>
            <div className={`bg-white overflow-auto w-[540px]  rounded-md transition-all relative p-3 ease-in-out duration-300 transform`}>
                <div className="p-4">
                    <p className='text-lg font-semibold'>{serviceObj?.productName}</p>
                    <div className='grid border p-2 mt-6 border-1 border-lightGray grid-cols-4 items-center'>
                        <div className='col-span-2 p-2 '>
                            <p className='font-semibold'>Staff</p>
                        </div>
                        <div className='col-span-2'>
                            <select value={obj?.teamMemberId || ""}
                                disabled={blockDropdown() || loader}
                                onChange={(e) => { setObj({ ...obj, staff: e.target.selectedOptions[0].getAttribute('data-set'), teamMemberId: e?.target?.value, }); }} className='p-2 w-full outline-none text-newLightBlue'>
                                <option disabled value="">{loader === true ? "Please Wait..." : teamMemberListToShow?.length === 0 ? "No Staff Found" : "Select Staff"}</option>
                                {
                                    teamMemberListToShow?.map((item) => {
                                        return (
                                            <option data-set={item?.name} value={item?.teamMemberId}>{item?.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className='grid max-h-[150px] overflow-y-auto border p-2 border-1 border-t-0 border-lightGray grid-cols-4 items-start'>
                        <div className='col-span-2 p-2 '>
                            <p className='font-semibold'>Variation</p>
                        </div>
                        <div className='col-span-2 flex flex-col gap-5'>

                            {
                                productVariants?.map((item) => {
                                    return (
                                        <div className='flex  items-center gap-3'>
                                            <input className='cursor-pointer' onChange={() => { handleCheckBox(item) }}
                                                // disabled={item?.teamMemberId !== obj?.teamMemberId ? true : false}
                                                name="productVariant" checked={obj?.variantId === item?.variantId && obj?.teamId === item?.teamMemberId} type="radio" />
                                            <p className='font-semibold text-sm'>{item?.name}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="flex gap-4 justify-between items-center w-full mt-6">
                        <button onClick={() => { setInnerModal("") }} className='bg-transparent text-lightBlue disabled:bg-gray-400 font-semibold  border border-1 border-lightBlue  outline-none rounded-md py-1 px-6 '>Cancel</button>
                        <button disabled={btnLoader || loader} onClick={() => { handleSave() }} className='bg-newLightBlue disabled:bg-gray-400 font-semibold text-white border-none outline-none rounded-md py-1 px-6 '> {btnLoader ? <Spinner /> : "Save"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSelection