import { Outlet, useLocation, useNavigate } from "react-router-dom"
import useUserRole from "../hooks/useUserRole";
import { useEffect } from "react";
import loaderGif from "../assets/mega.gif"

export const Protected = () => {
    const roles = useUserRole()
    const location = useLocation()
    const navigate = useNavigate()

    let isExist = null
    isExist = roles?.hide?.includes(location.pathname)

    useEffect(() => {
        if (isExist) {
            navigate("/calender")
        }
    }, [location.pathname])

    if (isExist === null) {
        return <div className='flex  flex-col justify-center items-center h-screen w-full'>
            <img src={loaderGif} alt="loaderGif" className='w-[100px]' />
        </div>
    }

    return <Outlet />
}