import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { lazy } from "react";
import DashboardLayout from "../Layouts/DashboardLayout";
import AuthGuard from "../Guard/AuthGuard";
import UserAuthGuard from "../Guard/UserAuthGuard";
import GuestLayout from '../Layouts/GuestLayout'
import { Protected } from "../hooks/Protected";

const CommunicationLog = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/CommunicationLog")), 1300);
    });
});


const Login = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Login")), 1300);
    });
});

const Directory = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Directory")), 1300);
    });
});


const Overview = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Overview")), 1300);
    });
});

const Calender = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Calender")), 1300);
    });
});

const Plus = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/Plus")), 1300);
    });
});

// const AssignProduct = lazy(() => {
//     return new Promise((resolve) => {
//         setTimeout(() => resolve(import("../pages/AssignProduct")), 1300);
//     });
// });

const CommissionManagement = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/CommissionManagement")), 1300);
    });
});

const TeamManagement = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(import("../pages/TeamManagement")), 1300);
    });
});


const token = localStorage.getItem("beautyToken");

export const router = createBrowserRouter([
    {
        path: "*",
        element: token ? (
            <Navigate to="/calender" replace />
        ) : (
            <Navigate to="/login" replace />
        ),
    },
    {
        element: <UserAuthGuard />,
        children: [
            {
                element: <GuestLayout />,
                children: [
                    {
                        path: "/login",
                        element: <Login />,
                    },
                ],
            },
        ],
    },
    {
        element: <AuthGuard />,
        children: [
            {
                element: <DashboardLayout />,
                children: [
                    {
                        element: <Protected />,
                        children: [
                            {
                                path: "/communicationLog",
                                element: <CommunicationLog />,
                            },
                            {
                                path: "/directory",
                                element: <Directory />,
                            },
                            {
                                path: "/overview",
                                element: <Overview />,
                            },
                            {
                                path: "/calender",
                                element: <Calender />,
                            },
                            {
                                path: "/plus",
                                element: <Plus />,
                            },
                            // {
                            //     path: "/assignProduct",
                            //     element: <AssignProduct />,
                            // },
                            {
                                path: "/commissionManagement",
                                element: <CommissionManagement />,
                            },
                            {
                                path: "/teamManagement",
                                element: <TeamManagement />,
                            },
                        ]
                    }
                ],
            },
        ],
    },
]);
