export const getRoles = (userType) => {
    const roles = {
        create: false,
        edit: false,
        redeem: false,
        hide: []
    };

    if (userType === 'superadmin') {
        roles.create = true;
        roles.redeem = false;
        roles.edit = true;
        roles.hide = ['']
    }

    else if (userType === 'admin') {
        roles.create = false;
        roles.redeem = false;
        roles.edit = false;
        roles.hide = ['/commissionManagement', "/teamManagement"]

    }
    return roles;
};