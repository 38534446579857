import React from 'react'

const List = ({ item, handleNavigate, Icon, index }) => {
    return (
        <>
            <li
                key={index}
                onClick={() => handleNavigate(item)}
                className={`${item?.select ? "text-primary" : "text-black"
                    } font-[500] text-[17px] px-3 py-2 laptop:text-sm laptopScreenHeight:text-sm rounded-xl flex items-center capitalize hover:opacity-40  cursor-pointer transition-all ease-out`}
            >
                {Icon && <Icon className="text-xl mr-3" />}
                {item.name}
            </li>
        </>
    )
}

export default List