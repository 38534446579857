import React from 'react'
import { Outlet } from "react-router-dom";

const GuestLayout = ({ children }) => {

    return (
        <Outlet />
    )
}

export default GuestLayout