import React from 'react'
import List from './List';
import Dropdown from './Dropdown';


const ListSection = ({ list, handleNavigate, sidebarRef, open }) => {
    return (
        <div
            ref={sidebarRef}
            className={`absolute overflow-auto bg-gray-100  shadow-2xl drop-shadow-lg  bottom-0 left-0 ml-0 w-[200px] z-[999]  px-2 py-4 transition-all ease-in-out duration-300 transform ${open ? "translate-y-[-22%] absolute " : "-translate-y-[-200%] absolute"
                }`}
        >
            <div className="pl-2 mt-4 flex flex-col items-start justify-center w-full">
                <ul className='w-full'>
                    {list?.map((item, index) => {
                        const { Icon } = item;
                        if (item?.subMenu?.length > 0) {
                            return <Dropdown key={index} lableName={item} item={item} />
                        } else {
                            return <div key={index}>
                                <List index={index} Icon={Icon} item={item} handleNavigate={handleNavigate} />
                            </div>
                        }
                    })}
                </ul>
            </div>
        </div>
    )
}

export default ListSection