import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../redux/slices/sidebarValue";
import ListSection from "./ListSection";
import { MainSidebarList } from "../../Routes/mainSidebarList";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state?.sidebarValue);
  const sidebarRef = useRef();


  const handleNavigate = (item) => {
    if (item?.url) {
      dispatch(setOpen(false));
      navigate(`${item?.url}`);
    }
  };

  const handleOutsideClick = (event) => {
    // if (window?.innerWidth < 1100) {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      // Clicked outside the sidebar, close it
      dispatch(setOpen(false));
    }
    // }
  };


  useEffect(() => {
    // const handleResize = () => {
    //   dispatch(setOpen(window.innerWidth > 1100));
    // };
    // window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      // window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleOutsideClick);
    };
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <ListSection open={open} sidebarRef={sidebarRef} list={MainSidebarList()} handleNavigate={handleNavigate} />
    </>
  );
};

export default Sidebar;
