
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";

const appointmentInstance = axios.create({
    baseURL: `${baseUrl}/api/Appointment`,
    headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
    },
});


let isTokenExpiredModalVisible = false

appointmentInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("beautyToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

appointmentInstance.interceptors.response.use(
    (response) => {
        if (response?.data?.message === "Token is not valid") {
            if (!isTokenExpiredModalVisible) {
                isTokenExpiredModalVisible = true;
                // Trigger an event to show the modal
                const event = new Event("showTokenExpiredModal");
                document.dispatchEvent(event);
                window.localStorage.clear()
                window.location.reload()
            }
        } else {
            return response
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default appointmentInstance