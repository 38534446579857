
import React, { useEffect, useState } from 'react'
import { RxCross1 } from "react-icons/rx";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import CalenderAvailability from '../CalenderAvailability';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import Spinner from '../Spinner';
import { addDays, isAfter, isSameDay, parseISO, endOfMonth } from 'date-fns';
import teamMemberInstance from '../../Instance/teamMemberInstance';
import { divideTimeSlots, getFirstDayOfMonth, parseDateTime } from '../../utils/timeSlot';
import appointmentInstance from '../../Instance/appointmentInstance';

const FindAvailability = ({ setSpecialAllow, specialAllow, editView, gettingTotalDuration, setServicesToShow, objInfo, setRescheduleView, rescheduleView, isAppointmentAvailable, setValue, duration, setDuration, setSmallLoader, smallLoader, setLoader, loader, calenderData, setCalenderData, currentMonth, setCurrentMonth, gettingCalenderInfo, timeSlots, setTimeSlots, servicesToShow, setInnerModal, teamMemberId, setTeamMemberId, teamMemberList, formValues }) => {
    const [selectedDate, setSelectedDate] = React.useState(formValues?.startAt ? formValues?.startAt : "");
    const currentDate = moment().format('MMMM/DD/YYYY')
    const rescheduleDate = moment(objInfo?.startAt?.split("+")[0]).format('MMMM/DD/YYYY')
    const [teamMemberListToShow, setTeamMemberListToShow] = useState()
    const [previousTeamMemberId] = useState(teamMemberId)
    const [previousTeamMemberName] = useState(servicesToShow?.length > 0 && servicesToShow[0]?.staff)
    const today = parseDateTime(currentMonth);
    const [abortController, setAbortController] = useState(new AbortController());

    // getting teamMember according to product Id
    const gettingTeamMember = async () => {
        try {
            const serviceIds = servicesToShow?.map((item) => item?.itemId)
            const variantIds = servicesToShow?.map((item) => item?.serviceVariationId)
            const body = {
                serviceIds,
                variantIds
            }
            const res = await teamMemberInstance.post(`TeamMemberServices`, body)
            setTeamMemberListToShow(res?.data?.data)
        } catch (error) {
            toast.error("Some error occured getting team Member")
        }
    }

    // block dropdown if status is in progress or completed
    const blockDropdown = () => {
        return objInfo?.status === "IN_PROGRESS" || objInfo?.status === "COMPLETED" ? true : false
    }


    const handleUserChange = async (event, name) => {
        if (event.target.value === "any") {
            setTimeSlots([])
            setSpecialAllow([])
            setCalenderData([])
            setTeamMemberId(event.target.value);
            setSelectedDate(null)
        } else {
            setSelectedDate(null)
            setCalenderData([])
            setTimeSlots([])
            setSpecialAllow([])
            setTeamMemberId(event.target.value);
            const tempService = [...servicesToShow]?.map((item) => {
                return { ...item, teamMemberId: event?.target?.value, staff: name }
            })
            setServicesToShow(tempService)
        }
        setValue("startAt", null)
    };

    const handleMonthChange = (increment) => {
        if (teamMemberId === "any") {
            setCurrentMonth(prevMonth => {
                const newMonth = new Date(prevMonth);
                newMonth.setMonth(prevMonth.getMonth() + increment);
                return newMonth;
            });
            // const tempMonth = new Date(currentMonth)
            // tempMonth.setMonth(currentMonth.getMonth() + increment);
        }
        else {
            setCurrentMonth(prevMonth => {
                const newMonth = new Date(prevMonth);
                newMonth.setMonth(prevMonth.getMonth() + increment);
                return newMonth;
            });
            const tempMonth = new Date(currentMonth)
            tempMonth.setMonth(currentMonth.getMonth() + increment);
            gettingCalenderInfo(true, null, tempMonth)
        }
        // setSelectedDate(null)
    };

    // handle time slot
    const handleTimeSlot = (item) => {
        if (editView) {
            if (rescheduleDate !== moment(selectedDate).format('MMMM/DD/YYYY')) {

                if (teamMemberId === "any") {
                    setTeamMemberId(item?.id)
                    const tempService = [...servicesToShow]?.map((item) => {
                        return { ...item, teamMemberId: item?.id, staff: item?.name }
                    })
                    setServicesToShow(tempService)
                }
                setDuration(item);
                setValue("startAt", item?.from)
                let startDate = moment(rescheduleDate).format('MM/DD/YYYY')
                let currentDate = moment().format('MM/DD/YYYY')
                let clickDate = moment(selectedDate).format('MM/DD/YYYY')

                const temp1 = new Date(startDate);
                const temp2 = new Date(currentDate);
                const temp3 = new Date(clickDate);

                if (
                    temp1.getTime() === temp2.getTime() &&
                    temp2.getTime() === temp3.getTime()
                ) return

                if (temp1.getTime() > temp2.getTime()) return
                setInnerModal("rescheduleAppointment")
            }

        }
        if (rescheduleView) {
            if (rescheduleDate === currentDate) {
                if (teamMemberId === "any") {
                    setTeamMemberId(item?.id)
                    const tempService = [...servicesToShow]?.map((res) => {
                        return { ...res, teamMemberId: item?.id, staff: item?.name }
                    })
                    setServicesToShow(tempService)
                }
                setDuration(item);
                setValue("startAt", item?.from)
                setInnerModal("rescheduleAppointment")
                setRescheduleView(false)
            } else {
                if (teamMemberId === "any") {
                    setTeamMemberId(item?.id)
                    const tempService = [...servicesToShow]?.map((res) => {
                        return { ...res, teamMemberId: item?.id, staff: item?.name }
                    })
                    setServicesToShow(tempService)
                }
                setInnerModal("")
                setValue("startAt", item?.from)
                setDuration(item);
                setRescheduleView(false)
            }
        } else {
            if (teamMemberId === "any") {
                setTeamMemberId(item?.id)
                const tempService = [...servicesToShow]?.map((res) => {
                    return { ...res, teamMemberId: item?.id, staff: item?.name }
                })
                setServicesToShow(tempService)
            }
            setDuration(item);
            setValue("startAt", item?.from)
        }
    }

    // handle close modal 
    const handleCloseModal = () => {
        const tempService = [...servicesToShow]?.map((item) => {
            return { ...item, teamMemberId: teamMemberId === "any" ? servicesToShow[0]?.teamMemberId : previousTeamMemberId, staff: teamMemberId === "any" ? servicesToShow[0]?.staff : previousTeamMemberName }
        })
        setServicesToShow(tempService)
        setInnerModal("");
        setValue("startAt", null)
        setDuration(null)
        setTimeSlots([])
        setSpecialAllow([])
        setTeamMemberId(teamMemberId === "any" ? servicesToShow[0]?.teamMemberId : previousTeamMemberId)
    }


    // getting calender data  with any available
    const gettingCalenderAnyData = async (smallLoad = false, date = null, month = null) => {
        try {
            if (teamMemberId) {
                if (smallLoad === true) {
                    setSmallLoader(true)
                } else {
                    setLoader(true)
                }
                abortController.abort();
                const newAbortController = new AbortController();
                setAbortController(newAbortController);
                const serviceIds = servicesToShow?.map((item) => item?.itemId)
                const variantId = servicesToShow?.map((item) => item?.variantId)
                const servicesBody = {
                    serviceIds: serviceIds,
                    variantIds: variantId,
                }
                const res = await appointmentInstance.post(`/V2/FindAvailability?date=${moment(date ? date : selectedDate)?.format("YYYY-MM-DD")}&duration=${gettingTotalDuration()}`, servicesBody, {
                    signal: newAbortController.signal
                })
                const data = res?.data?.data;
                if (data) {
                    const dividedTimeSlots = divideTimeSlots(data, gettingTotalDuration());
                    setCalenderData(dividedTimeSlots)
                    const arr = []
                    dividedTimeSlots.forEach((item) => {
                        let obj = { name: item?.name };
                        item?.timeSlots?.forEach((res) => {
                            let newObj = { ...obj, ...res, id: item?.id };
                            arr.push(newObj);
                        });
                    });
                    setTimeSlots(arr)
                }
                if (smallLoad === true) {
                    setSmallLoader(false)
                } else {
                    setLoader(false)
                }
            }
        } catch (err) {
            if (err?.message === "canceled") {
                return
            }
            if (smallLoad === true) {
                setSmallLoader(false)
            } else {
                setLoader(false)
            }
            toast.error("Some error occured while getting calender info")
        }
    }

    useEffect(() => {
        gettingTeamMember()
    }, [servicesToShow])



    // calender week slots options click
    const handleOptionClick = async (days) => {
        const newDate = addDays(formValues?.startAt ? formValues?.startAt : new Date(), days);
        try {
            // with Any Available 
            if (teamMemberId === "any") {
                if (isAfter(newDate, endOfMonth(today))) {
                    const nextMonth = new Date(currentMonth)
                    nextMonth.setMonth(nextMonth.getMonth() + 1)
                    setSmallLoader(true)
                    abortController.abort();
                    const newAbortController = new AbortController();
                    setAbortController(newAbortController);
                    const serviceIds = servicesToShow?.map((item) => item?.itemId)
                    const variantId = servicesToShow?.map((item) => item?.variantId)
                    const servicesBody = {
                        serviceIds: serviceIds,
                        variantIds: variantId,
                    }
                    const res = await appointmentInstance.post(`/V2/FindAvailability?date=${moment(newDate)?.format("YYYY-MM-DD")}&duration=${gettingTotalDuration()}`, servicesBody, {
                        signal: newAbortController.signal
                    })
                    const data = res?.data?.data;
                    if (data) {
                        const dividedTimeSlots = divideTimeSlots(data, gettingTotalDuration());
                        setCalenderData(dividedTimeSlots)
                        const arr = []
                        dividedTimeSlots.forEach((item) => {
                            let obj = { name: item?.name }; // Name ko pehli dafa push karna
                            item?.timeSlots?.forEach((res) => {
                                let newObj = { ...obj, ...res, id: item?.id }; // TimeSlot ke saath id ko bhi merge karna
                                arr.push(newObj);
                            });
                        });
                        setTimeSlots(arr)
                    }
                    setCurrentMonth(newDate)
                    setSelectedDate(newDate)
                    setSmallLoader(false)
                }
                else {
                    setSmallLoader(true)
                    const serviceIds = servicesToShow?.map((item) => item?.itemId)
                    const variantId = servicesToShow?.map((item) => item?.variantId)
                    const servicesBody = {
                        serviceIds: serviceIds,
                        variantIds: variantId,
                    }
                    abortController.abort();
                    const newAbortController = new AbortController();
                    setAbortController(newAbortController);
                    const res = await appointmentInstance.post(`/V2/FindAvailability?date=${moment(newDate)?.format("YYYY-MM-DD")}&duration=${gettingTotalDuration()}`, servicesBody, {
                        signal: newAbortController.signal
                    })
                    const data = res?.data?.data;
                    if (data) {
                        const dividedTimeSlots = divideTimeSlots(data, gettingTotalDuration());
                        setCalenderData(dividedTimeSlots)
                        const arr = []
                        dividedTimeSlots.forEach((item) => {
                            let obj = { name: item?.name }; // Name ko pehli dafa push karna
                            item?.timeSlots?.forEach((res) => {
                                let newObj = { ...obj, ...res, id: item?.id }; // TimeSlot ke saath id ko bhi merge karna
                                arr.push(newObj);
                            });
                        });
                        setTimeSlots(arr)
                    }
                    setCurrentMonth(newDate)
                    setSelectedDate(newDate)
                    setSmallLoader(false)
                }
            }

            // with teamMember Id normally going
            else {
                if (isAfter(newDate, endOfMonth(today))) {
                    const nextMonth = new Date(currentMonth)
                    nextMonth.setMonth(nextMonth.getMonth() + 1)
                    setSmallLoader(true)
                    abortController.abort();
                    const newAbortController = new AbortController();
                    setAbortController(newAbortController);
                    const res = await appointmentInstance.get(`FindAvailability?teamMemberId=${teamMemberId}&date=${getFirstDayOfMonth(nextMonth)}&duration=${gettingTotalDuration()}`, {
                        signal: newAbortController.signal
                    })
                    const data = res?.data;
                    if (res?.data?.message === "Team member not found") {
                        return
                    }
                    const dividedTimeSlots = divideTimeSlots(data, gettingTotalDuration());
                    setCalenderData(dividedTimeSlots)
                    const selectedDateObj = dividedTimeSlots?.find(item => isSameDay(parseISO(item.date), newDate));
                    setCurrentMonth(newDate)
                    setSelectedDate(newDate)
                    setSmallLoader(false)
                    if (selectedDateObj !== undefined) {
                        setTimeSlots(selectedDateObj?.timeSlots)
                        setSpecialAllow(selectedDateObj?.specialSlots)
                    } else {
                        setTimeSlots([])
                        setSpecialAllow([])
                    }
                }
                else {
                    setSmallLoader(true)
                    abortController.abort();
                    const newAbortController = new AbortController();
                    setAbortController(newAbortController);
                    const res = await appointmentInstance.get(`FindAvailability?teamMemberId=${teamMemberId}&date=${getFirstDayOfMonth(newDate)}&duration=${gettingTotalDuration()}`, {
                        signal: newAbortController.signal
                    })
                    const data = res?.data;
                    if (res?.data?.message === "Team member not found") {
                        return
                    }
                    const dividedTimeSlots = divideTimeSlots(data, gettingTotalDuration());
                    setCalenderData(dividedTimeSlots)
                    const selectedDateObj = dividedTimeSlots?.find(item => isSameDay(parseISO(item.date), newDate));
                    setCurrentMonth(newDate)
                    setSelectedDate(newDate)
                    setSmallLoader(false)
                    if (selectedDateObj !== undefined) {
                        setTimeSlots(selectedDateObj?.timeSlots)
                        setSpecialAllow(selectedDateObj?.specialSlots)
                    } else {
                        setTimeSlots([])
                        setSpecialAllow([])
                    }
                }
            }
        } catch (err) {
            if (err?.message === "canceled") {
                return
            }
            toast.error("some error occured while getting calender calender")
        }

    };

    const checkOverlap = (mainObject) => {
        const specialAllow = [
            {
                "from": "2024-10-16T13:00:00",
                "to": "2024-10-16T13:30:00"
            },
            {
                "from": "2024-10-16T13:00:00",
                "to": "2024-10-16T14:30:00"
            },
            {
                "from": "2024-10-16T15:00:00",
                "to": "2024-10-16T15:30:00"
            },
            {
                "from": "2024-10-16T11:00:00",
                "to": "2024-10-16T14:30:00"
            },
        ];

        const temp = mainObject.to?.split("+")[0];

        // Format the mainObject from and to times
        const mainFrom = moment(mainObject.from).format("HH:mm");  // Using 24-hour format for clear comparison
        const mainTo = moment(temp).format("HH:mm");

        // Check if mainObject's time is within specialAllow range
        const check = specialAllow.some((item) => {
            const itemFrom = moment(item.from).format("HH:mm");
            const itemTo = moment(item.to).format("HH:mm");

            // Check if the mainObject time is fully within the specialAllow range
            return (mainFrom >= itemFrom && mainTo <= itemTo);
        });

        return check ? true : false;
    };


    useEffect(() => {
        if (servicesToShow?.length > 0 && gettingTotalDuration() > 0 && teamMemberId === "any" && selectedDate) {
            gettingCalenderAnyData()
        }
    }, [servicesToShow])


    return (
        <div className={` w-full h-full flex justify-end z-[9999] bg-[#4a4a4aa0] rounded-md fixed top-0 right-0`}>
            <div className='flex flex-col z-20 p-7 items-center w-[460px] bg-white'>
                <div className='sticky top-0 z-20 w-full flex justify-between items-center'>
                    <RxCross1 className='cursor-pointer bg-[#F2F2F2] p-2 text-4xl font-semibold ' onClick={() => { handleCloseModal() }} />
                    <p className='font-bold text-lg'>Find Availability</p>
                    <button
                        disabled={!formValues?.startAt}
                        onClick={() => {
                            setInnerModal("");
                        }}
                        className={` disabled:bg-[#F2F2F2] cursor-pointer bg-newLightBlue text-white p-2 disabled:text-gray-500 font-semibold`}
                    >
                        Done
                    </button>
                </div>
                <div className='grid border  w-full mt-6 border-1 border-lightGray grid-cols-10 items-center'>
                    <div className='col-span-5  bg-[#C7DBF8] pl-4 p-2'>
                        <p className='font-semibold '>Staff</p>
                    </div>
                    <div className='col-span-5'>
                        <select
                            disabled={blockDropdown()}
                            value={teamMemberId} onChange={(e) => { handleUserChange(e, e.target.selectedOptions[0].getAttribute('data-set')) }} className='p-2 w-full text-newLightBlue'>
                            <option disabled selected value="">Select Staff</option>
                            <option value="any">Any Available</option>
                            {teamMemberListToShow?.map((item) => {
                                return (
                                    <option data-set={item?.name} value={item?.teamMemberId}>{item?.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                {loader === true ?
                    <div className='mt-10'>
                        <Spinner />
                    </div>
                    :
                    <>
                        <div className='flex flex-col mt-3 w-full px-2 items-center'>
                            <div className='flex gap-4 justify-between items-start'>
                                <div className='flex flex-col'>
                                    <div className='w-full flex justify-between items-center'>
                                        <p className='font-bold text-xl ml-3'>{currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}</p>
                                        <div className='flex gap-3'>
                                            <FaArrowLeft className='cursor-pointer bg-[#F2F2F2] p-3 text-4xl' onClick={() => handleMonthChange(-1)} />
                                            <FaArrowRight className='cursor-pointer bg-[#F2F2F2] p-3 text-4xl' onClick={() => handleMonthChange(1)} />
                                        </div>
                                    </div>
                                    <CalenderAvailability
                                        gettingCalenderAnyData={gettingCalenderAnyData}
                                        teamMemberId={teamMemberId}
                                        gettingCalenderInfo={gettingCalenderInfo}
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                        currentMonth={currentMonth}
                                        calenderData={calenderData}
                                        setTimeSlots={setTimeSlots}
                                        timeSlots={timeSlots}
                                        setSpecialAllow={setSpecialAllow}
                                    />
                                </div>
                                <div className='flex flex-col items-center gap-3'>
                                    <button className='text-newLightBlue text-[12.5px] cursor-pointer bg-[#F2F2F2] py-2 px-1 w-[100px] font-light' onClick={() => handleOptionClick(0)}>Today</button>
                                    <button className='text-newLightBlue text-[12.5px] cursor-pointer bg-[#F2F2F2] py-2 px-1 w-[100px] font-light' onClick={() => handleOptionClick(7)}>1 Week</button>
                                    <button className='text-newLightBlue text-[12.5px] cursor-pointer bg-[#F2F2F2] py-2 px-1 w-[100px] font-light' onClick={() => handleOptionClick(14)}>2 Weeks</button>
                                    <button className='text-newLightBlue text-[12.5px] cursor-pointer bg-[#F2F2F2] py-2 px-1 w-[100px] font-light' onClick={() => handleOptionClick(21)}>3 Weeks</button>
                                    <button className='text-newLightBlue text-[12.5px] cursor-pointer bg-[#F2F2F2] py-2 px-1 w-[100px] font-light' onClick={() => handleOptionClick(28)}>4 Weeks</button>
                                    <button className='text-newLightBlue text-[12.5px] cursor-pointer bg-[#F2F2F2] py-2 px-1 w-[100px] font-light' onClick={() => handleOptionClick(35)}>5 Weeks</button>
                                    <button className='text-newLightBlue text-[12.5px] cursor-pointer bg-[#F2F2F2] py-2 px-1 w-[100px] font-light' onClick={() => handleOptionClick(42)}>6 Weeks</button>
                                </div>

                            </div>
                        </div>



                        <div className='flex flex-col mt-6 w-full h-[300px] overflow-y-auto'>
                            <div className='flex gap-3 items-center sticky top-0 bg-white'>
                                {selectedDate && <p className='font-semibold text-lg'>Availability for {moment(selectedDate)?.format("ddd")}, {moment(selectedDate)?.format('MMM DD')} </p>}
                                {smallLoader && <Spinner extraClass="!h-5 !w-5" />}
                            </div>
                            <div className='flex gap-2 flex-wrap'>
                                {!smallLoader && selectedDate && timeSlots?.length > 0 &&
                                    timeSlots?.map((item, index) => {
                                        // const isSelected = checkOverlap(item);
                                        const isFirstOfId = index === 0 || timeSlots[index - 1]?.id !== item?.id;
                                        return (
                                            <>
                                                {isFirstOfId && item?.name &&
                                                    <div className='flex w-full my-2 sticky top-[25px]  bg-white z-10 flex-wrap'>
                                                        <p className='text-[14px] font-medium'>
                                                            Specialist Name : <span className='text-[15px] font-normal'>{item?.name}</span>
                                                        </p>
                                                    </div>
                                                }
                                                {/* ${isSelected ? "!bg-orange-600 !text-white" : ""}  */}
                                                <div className='flex  flex-wrap'>
                                                    <div onClick={() => { handleTimeSlot(item) }} className={`flex cursor-pointer  items-center  `}>
                                                        <div className={`flex  ${item?.from === duration?.from && "bg-black text-white"} text-newLightBlue bg-[#F2F2F2] p-2 gap-4`}>
                                                            <p className='text-sm  font-semibold'>{moment(item?.from).format("h:mm a")}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    })
                                }
                            </div>
                            {!smallLoader && selectedDate && timeSlots?.length === 0 && <p className='text-sm mt-3 font-semibold'>No Time Slot Found</p>}
                        </div>
                    </>
                }

            </div>
        </div >
    )
}

export default FindAvailability
