export const durationListSystem = [
    {
        name: "1m",
        value: "1",
    },
    {
        name: "5m",
        value: "5",
    },
    {
        name: "10m",
        value: "10",
    },
    {
        name: "15m",
        value: "15",
    },
    {
        name: "20m",
        value: "20",
    },
    {
        name: "30m",
        value: "30",
    },
    {
        name: "40m",
        value: "40",
    },
    {
        name: "50m",
        value: "50",
    },
    {
        name: "60m",
        value: "60",
    },
]