import './App.css'
import { Suspense, useEffect, useRef } from 'react';
import { RouterProvider } from "react-router-dom"
import { router } from './Routes/Route';
import { ToastContainer } from 'react-toastify';
import loaderGif from "./assets/mega.gif"

function App() {

    const token = localStorage?.getItem("beautyToken");
    const time = 7200000  // expiration time
    const logoutTimerRef = useRef(null);

    const startLogoutTimer = () => {
        logoutTimerRef.current = setTimeout(logoutUser, time);
    };

    const resetLogoutTimer = () => {
        clearTimeout(logoutTimerRef.current);
        if (token) {
            startLogoutTimer();
        }
    };

    const logoutUser = () => {
        window.localStorage.clear()
        window.location.reload()
    };

    useEffect(() => {
        let activityTimeout;

        const handleUserActivity = () => {
            clearTimeout(activityTimeout);
            clearTimeout(logoutTimerRef.current);
            activityTimeout = setTimeout(resetLogoutTimer, time); // 1 minute in milliseconds
        };

        const events = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

        for (const event of events) {
            document.addEventListener(event, handleUserActivity);
        }

        startLogoutTimer();
        activityTimeout = setTimeout(resetLogoutTimer, time); // 1 minute in milliseconds

        return () => {
            for (const event of events) {
                document.removeEventListener(event, handleUserActivity);
            }
            clearTimeout(activityTimeout);
            clearTimeout(logoutTimerRef.current);
        };
    }, []);

    return (
        <>
            <Suspense fallback={
                <div className='flex  flex-col justify-center items-center h-screen w-full'>
                    <img src={loaderGif} alt="loaderGif" className='w-[100px]' />
                </div>
            }>
                <RouterProvider router={router} />
            </Suspense>
            <ToastContainer closeOnClick={true} />
        </>
    )
}

export default App
