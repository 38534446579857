import React from 'react'
import { Outlet } from "react-router-dom";
import BottomNav from '../components/BottomNav';
import Navbar from '../components/Navbar';

const DashboardLayout = ({ children }) => {

    return (
        <>
            <div className='w-full flex fixed h-screen'>
                <div className='w-full overflow-y-scroll flex-col px-6 mt-5'>
                    <Navbar />
                    <Outlet>
                        {children}
                    </Outlet>
                    <BottomNav />
                </div>
            </div>
        </>
    )
}

export default DashboardLayout