import React from 'react'
import Spinner from '../Spinner'

const ConfirmationAppointment = ({ setClose, msg, handleCall, loader }) => {
    return (
        <div className='fixed top-0 left-0 w-full bg-[#4a4a4aa0] z-[99999] h-full drop-shadow-2xl  flex justify-center items-center'>
            <div className={`bg-white w-[400px] overflow-auto rounded-md transition-all relative p-3 ease-in-out duration-300 transform`}>
                {/* <RxCross2 size={25} className='cursor-pointer' /> */}
                <div className="p-4">
                    <p className='font-semibold text-lg'>{msg}</p>
                    <div className="flex gap-4 justify-end items-center w-full mt-4">
                        <p onClick={() => { setClose() }} className='cursor-pointer font-semibold'>No</p>
                        <button disabled={loader} onClick={() => { handleCall() }} className='bg-newLightBlue disabled:bg-gray-400 font-semibold text-white border-none outline-none rounded-md py-2 px-6 '>{loader ? <Spinner /> : "Yes"} </button>
                    </div>
                </div>
            </div>
        </div>)
}

export default ConfirmationAppointment