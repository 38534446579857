import { configureStore } from '@reduxjs/toolkit'
import sidebarValue from './slices/sidebarValue'
import apiCall from './slices/apiCall'

export const store = configureStore({
    reducer: {
        sidebarValue,
        apiCall
    },
})

export default store