import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    open: false,
    sideMenu: false,
}

export const sidebarValue = createSlice({
    name: 'sidebarValue',
    initialState,
    reducers: {
        setOpen: (state, action) =>
        {
            state.open = action.payload
        },
        setSideMenu: (state, action) =>
        {
            state.sideMenu = action.payload
        },
    },
})

export const { setOpen, setSideMenu } = sidebarValue.actions

export default sidebarValue.reducer