import { useLocation } from "react-router-dom";

// Icons Admin
import { MdCamera } from "react-icons/md";
import { IoStatsChartOutline } from "react-icons/io5";
import { LiaBoxesSolid } from "react-icons/lia";
import { GrTransaction } from "react-icons/gr";
import { FaRegAddressCard } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { GrUserManager } from "react-icons/gr";
import useUserRole from "../hooks/useUserRole";

export const MainSidebarList = () => {
    const location = useLocation();
    const roles = useUserRole()

    // roles?.hide?.includes(location.pathname)

    const list = [
        {
            name: "Resources",
            url: "/resources",
            select: location.pathname === "/resources" ? true : false,
            Icon: MdCamera,
        },
        {
            name: "Reports",
            url: "/reports",
            select:
                location.pathname === "/reports"
                    ? true
                    : false,
            Icon: IoStatsChartOutline,
        },
        {
            name: "Checkout",
            url: "/checkout",
            select:
                location.pathname === "/checkout"
                    ? true
                    : false,
            Icon: LiaBoxesSolid,
        },
        {
            name: "Transaction",
            url: "/transactions",
            select: location.pathname === "/transaction" ? true : false,
            Icon: GrTransaction,
        },
        {
            name: "Directory",
            url: "/directory",
            select: (location.pathname === "/directory" || location.pathname === "/communicationLog" || location.pathname === "/plus") ? true : false,
            Icon: FaRegAddressCard,
        },
        {
            name: "Calender",
            url: "/calender",
            select: (location.pathname === "/calender" || location.pathname === "/overview" || location.pathname === "/assignProduct") ? true : false,
            Icon: SlCalender,
        },
        {
            name: "Team Member",
            url: "/teamManagement",
            select: (location.pathname === "/teamManagement" || location.pathname === "/commissionManagement") ? true : false,
            Icon: GrUserManager,
        },
        // {
        //     name: "Dropwdown",
        //     url: "",
        //     Icon:
        //         location.pathname === "/"
        //             ? changeEmailYellow
        //             : changeEmailWhite,
        //     select: location.pathname === "/" ? true : false,
        //     subMenu: [
        //         {
        //             name: "Hello 1",
        //             url: ""
        //         },
        //         {
        //             name: "Hello 2",
        //             url: ""
        //         },
        //     ]
        // }
    ];

    const filerRoutes = list.filter(item => !roles?.hide?.includes(item?.url))

    return filerRoutes

}