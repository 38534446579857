import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    call: false,
}

export const apiCall = createSlice({
    name: 'apiCall',
    initialState,
    reducers: {
        setCall: (state, action) => {
            state.call = action.payload
        },
    },
})

export const { setCall } = apiCall.actions

export default apiCall.reducer