import React, { useState } from 'react'
import logo from "../../assets/Mega-01.png"
import CreateAppointment from '../Modals/CreateAppointment'
import EditAppointment from '../Modals/EditAppointment'
import { PiChatsCircle } from 'react-icons/pi'
import { IoIosNotificationsOutline } from 'react-icons/io'
import { FaPlus } from 'react-icons/fa6'


const Navbar = () => {
    const name = localStorage?.getItem("beautyName")
    const [modal, setModal] = useState("")

    const handleLogout = () => {
        window.localStorage.clear();
        window.location.reload();
    }

    return (
        <div className='flex w-full px-6 justify-between items-center'>
            {modal === "createAppointment" && <CreateAppointment setModal={setModal} />}
            {modal === "editAppointment" && <EditAppointment setModal={setModal} />}
            <div className='flex items-center gap-10 md:gap-4'>
                <p className='text-xl font-medium md:text-sm'>Hello, {name && name}</p>
                <p onClick={handleLogout} className='text-xl mt-0 cursor-pointer font-medium md:text-sm'>Logout</p>

                <div className='flex items-center gap-3 ml-10'>
                    <PiChatsCircle className='text-black text-2xl' />
                    <IoIosNotificationsOutline className='text-2xl' />
                    <FaPlus onClick={() => { setModal("createAppointment") }} className='text-2xl cursor-pointer' />
                </div>
            </div>

            <div>
                <img className='w-44 laptop:w-36 md:!w-16' src={logo} alt="logo" />
            </div>

        </div>
    )
}

export default Navbar