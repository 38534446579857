import React from 'react'

const ErrorMsg = ({ msg, extraClass }) => {
    return (
        <div className='w-full text-center'>
            <p className={`${extraClass} font-bold text-lg`}>{msg || "No Data Found"}</p>
        </div>
    )
}

export default ErrorMsg