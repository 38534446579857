import React from 'react'
import { TiThMenu } from "react-icons/ti";
import { FaRegAddressCard } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { setOpen } from '../../redux/slices/sidebarValue';
import { useSelector, useDispatch } from 'react-redux';
import { SlCalender } from "react-icons/sl";
import { HiMenu } from "react-icons/hi";
import Sidebar from '../Sidebar';

const BottomNav = () => {
    const { open } = useSelector((state) => state?.sidebarValue)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const directory = [
        {
            name: "Directory",
            url: "directory",
            select: location.pathname === "/directory" ? true : false,
            Icon: FaRegAddressCard,
        },
        {
            name: "Communication Log",
            url: "communicationLog",
            select:
                location.pathname === "/communicationLog"
                    ? true
                    : false,
        },
        {
            name: "Plus",
            url: "plus",
            select: location.pathname === "/plus" ? true : false,
        },
        {
            name: "Consent Forms",
            url: "",
            select: location.pathname === "/consentForms" ? true : false,
        },
        {
            name: "Setting",
            url: "",
            select: location.pathname === "/setting" ? true : false,
        },
    ];

    const calender = [
        {
            name: "Calender",
            url: "calender",
            select: location.pathname === "/calender" || location.pathname === "/assignProduct" ? true : false,
            Icon: SlCalender,
        },
        {
            name: "Overview",
            url: "overview",
            select:
                location.pathname === "/overview"
                    ? true
                    : false,
        },
        {
            name: "Waitlist",
            url: "",
            select: location.pathname === "/waitlist" ? true : false,
        },
        {
            name: "Settings",
            url: "",
            select: location.pathname === "/settings" ? true : false,
        },
    ];

    const team = [
        {
            name: "Team Management",
            url: "teamManagement",
            select: location.pathname === "/teamManagement" ? true : false,
        },
        {
            name: "Commission Management",
            url: "commissionManagement",
            select:
                location.pathname === "/commissionManagement"
                    ? true
                    : false,
        },
    ];

    const handleToggleSidebar = () => {
        dispatch(setOpen(!open))
    }

    const handleNavigate = (item) => {
        if (item?.url) {
            navigate(`/${item?.url}`);
        }
    };

    const renderList = () => {
        if (location?.pathname === "/directory" || location?.pathname === "/communicationLog" || location?.pathname === "/plus") {
            return directory
        }
        if (location?.pathname === "/overview" || location?.pathname === "/calender" || location.pathname === "/assignProduct") {
            return calender
        }
        if (location?.pathname === "/teamManagement" || location?.pathname === "/commissionManagement") {
            return team
        }
    }

    return (
        <div className='fixed z-[9991]  bottom-0 w-full bg-white'>
            <Sidebar />
            <div className='flex gap-1 footer relative justify-start items-center  py-4 laptop:text-[12px]'>
                <div className='flex gap-10 items-center md:flex-wrap'>
                    {open ?
                        <TiThMenu onClick={handleToggleSidebar} className='text-3xl md:text-xl cursor-pointer' />
                        :
                        <HiMenu onClick={handleToggleSidebar} className='text-3xl font-bold md:text-xl cursor-pointer' />
                    }
                    {renderList()?.map((item) => {
                        const { Icon } = item
                        return (
                            <div className='flex gap-1 items-center mb-1' key={item?.name}>
                                {Icon && <Icon className="text-lg" />}
                                <p onClick={() => { handleNavigate(item) }} className={`cursor-pointer font-[400] ${location?.pathname === `/${item?.url}` && "text-primary font-bold"} `}> {item?.name}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default BottomNav