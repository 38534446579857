import React from 'react'

const Dropdown = ({ item, lableName, key }) => {
    return (
        <>
            <li key={key}>
                <details className="group [&_summary::-webkit-details-marker]:hidden">
                    <summary
                        className="font-[500] text-[20px] px-3 py-2 laptop:text-sm laptopScreenHeight:text-sm rounded-xl flex items-center uppercase font-normalRegular  cursor-pointer transition-all ease-out text-white"
                    >
                        <img src={""} className="py-2 px-1 mr-3 w-8" alt="dashboardWhite" />
                        <span className="text-[20px] font-medium font-normalRegular uppercase"> {lableName?.name}</span>
                        <span className="shrink-0 transition ml-8 duration-300 group-open:-rotate-180">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                    </summary>
                    <ul className=" px-4">
                        {item?.subMenu?.map((item, index) => {
                            return (
                                <li key={index}>
                                    <a
                                        href="hello"
                                        className="block rounded-lg px-4 py-2 text-sm font-medium text-white uppercase font-normalRegular "
                                    >
                                        {item?.name}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </details>
            </li>
        </>
    )
}

export default Dropdown